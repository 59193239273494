import React from "react";
import { container, grid, card, intro } from "./index.module.less";
import iot from "images/photos/IOT.jpeg";
import datadriven from "images/photos/Data-Driven.png";
import plantempower from "images/photos/PlantEmpower-Features.jpeg";
import centralisedata from "images/photos/Centralize-data.png";
import analysetools from "images/photos/Analyse-tools.png";
import ai from "images/photos/AI.png";
import datacomparing from "images/photos/Data-comparing.png";
import datanalysis from "images/photos/Data-Analysis.png";
import monitoring from "images/photos/Monitoring.png";
import datahub from "images/photos/datahub.png";
import { Link } from "gatsby";
import blog1 from "images/photos/blog1.jpeg";
import blog2 from "images/photos/blog2.jpeg";

let images = [
  blog1,
  blog2,
  iot,
  datadriven,
  plantempower,
  centralisedata,
  analysetools,
  ai,
  datacomparing,
  datanalysis,
  monitoring,
  datahub,
 ]

const cropSummary = (longSummary) => {
  // we'll limit summaries length to max max_lenght
  console.log(longSummary)
  const max_lenght = 150 
  if(longSummary){
    if(longSummary.length <= max_lenght) return longSummary
    return longSummary.slice(0,max_lenght) + "..."
  }

  return "UNDEFINED SUMMARY"
}

const renderEntriesCards = (posts) => {
  let englishPosts = posts.filter((post)=>{
    return post.customFields.language == "EN"
  })

  // console.log({englishPosts})
  if(englishPosts.length > 0){
    return englishPosts.map((it, idx) => (
      <Link className={card} to={"" + it.id}>
        <div>
          <img src={images[idx]} alt={it.title} />
          <h3>{it.title}</h3>
          {/* <p style={{margin:"0px", padding:"16px"}}>{cropSummary(it.customFields["postsummary"])}</p> */}
        </div>
      </Link>
    ))
  }else{
    return <h2>No entries available...</h2>
  }
}

export default ({ posts }) => {
  // console.log("RECEIVED POSTS", posts)
  return (
    <div className={container}>
      {/* <div className={intro}>
        <h2>Latest News</h2>
        <p>
        </p>
      </div> */}
      <div className={grid}>
        {renderEntriesCards(posts)}
      </div>
    </div>
  );
};
