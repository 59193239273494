import React from "react";
import Header from "components/shared/Header";
import Layout from "components/shared/Layout";
import EntriesCards from "components/shared/EntriesCardsEN";
import Footer from "components/shared/FooterEN";
import Nav from "components/shared/NavEN";
import Meta from "components/shared/Meta";
import { graphql } from "gatsby";
import { intro } from "../index.module.less";

export default (queryResults) => {
  return (
    <>
      <Nav />
      <Meta url="en/news/" />
      <Header>
        <h1>News</h1>
        <p>Stay informed of the latest trends and developments</p>
      </Header>
      <Layout>
        <div className={intro}>
          <h2>Stay informed</h2>
          <p>
          LetsGrow.com keeps track of the latest developments in the agri- and horticultural sector. We happily share this knowledge with you.
          </p>
        </div>
        <EntriesCards posts={queryResults.data.allWpPost.nodes}/>
      </Layout>
      <Footer path="Features" />
    </>
  );
};

export const pageQuery = graphql`
query {
  allWpPost (
    sort: { fields: date, order: DESC }
  ){
    nodes {
      id
      uri
      title
      customFields {
        postsummary
        language
      }
    }
  }
}`;
